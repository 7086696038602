import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/docs/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "introduction"
    }}>{`Introduction`}</h1>
    <p>{`This service provides a GraphQL API for fetching/streaming live vehicle-positions.`}</p>
    <p>{`The API is explorable in our GraphQL IDE: `}<a parentName="p" {...{
        "href": "https://api.entur.io/graphql-explorer/vehicles-v2"
      }}>{`https://api.entur.io/graphql-explorer/vehicles-v2`}</a>{`.`}</p>
    <h2 {...{
      "id": "authentication"
    }}>{`Authentication`}</h2>
    <p>{`This API is open under NLOD licence, however, it is required that all consumers identify themselves by using the header ET-Client-Name. Entur will deploy strict rate-limiting policies on API-consumers who do not identify with a header and reserves the right to block unidentified consumers. The structure of ET-Client-Name should be: "company-application"`}</p>
    <p>{`Header examples:`}</p>
    <ul>
      <li parentName="ul">{`"brakar-journeyplanner"`}</li>
      <li parentName="ul">{`"fosen_utvikling-departureboard"`}</li>
      <li parentName="ul">{`"nor_way_bussekspress-nwy-app"`}</li>
    </ul>
    <h2 {...{
      "id": "query"
    }}>{`Query`}</h2>
    <p>{`Enables fetching vehicle-positions in a GraphQL API.`}</p>
    <p><strong parentName="p">{`Base URL:`}</strong>{` `}<a parentName="p" {...{
        "href": "https://api.entur.io/realtime/v2/vehicles/graphql"
      }}>{`https://api.entur.io/realtime/v2/vehicles/graphql`}</a></p>
    <p>{`Example:`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  vehicles(codespaceId:"ATB") {
    lastUpdated
    location {
      latitude
      longitude
    }
  }
}
`}</code></pre>
    <h2 {...{
      "id": "subscription"
    }}>{`Subscription`}</h2>
    <p>{`Enables creating a GraphQL-subscription that will open a websocket and let the server stream all updates to the client continuously.`}</p>
    <p><strong parentName="p">{`Base URL:`}</strong>{` wss://api.entur.io/realtime/v2/vehicles/subscriptions`}</p>
    <p>{`(Uses the websocket-protocol `}<inlineCode parentName="p">{`graphql-ws`}</inlineCode>{`)`}</p>
    <p>{`Example:`}</p>
    <pre><code parentName="pre" {...{}}>{`subscription {
  vehicles(codespaceId:"ATB") {
    lastUpdated
    location {
      latitude
      longitude
    }
  }
}
`}</code></pre>
    <p>{`For GraphQL-subscriptions, the Et-Client-Name header should be included in the initial connection request.`}</p>
    <p>{`Example (using the `}<inlineCode parentName="p">{`graphql-ws`}</inlineCode>{` client-library):`}</p>
    <pre><code parentName="pre" {...{}}>{`createClient({
  url: "wss://api.entur.io/realtime/v2/vehicles/subscriptions",
  connectionParams: {
    headers: {
      "Et-Client-Name": "company-application",
    },
  },
})
`}</code></pre>
    <p>{`More details about GraphQL-subscriptions: `}<a parentName="p" {...{
        "href": "https://graphql.org/blog/subscriptions-in-graphql-and-relay/"
      }}>{`https://graphql.org/blog/subscriptions-in-graphql-and-relay/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      